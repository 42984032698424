<script>
import CashboxListItem from "@/views/cashbox/ListItem";
import {computed, ref} from "vue";

/**
 * The cashbox`s list
 *
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
export default {
  name: "CashboxList",
  components: {CashboxListItem},
  props: {
    store: {
      type: Array,
      require: true
    }
  },
  setup(props) {
    const emptyText = computed(() => props.store.length === 0 ? 'История пуста...' : null)
    return {
      emptyText
    };
  }
}
</script>


<template>
  <cashbox-list-item v-for="item in store"
                     :item="item"></cashbox-list-item>
  <p v-if="emptyText"
     class="empty-text">{{ emptyText }}</p>

</template>

<style scoped lang="scss">
@import "../../scss/vars";

.empty-text {
  color: gray;
  font: italic 14px/1 $font-family-base;
  text-align: center;
}
</style>