import {computed, ref} from "vue"
import axios from "axios";
import {isEmpty} from "lodash"

/**
 * Manage global stats data
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
const fetchedData = ref({});
const hasActiveBox = computed(() => !isEmpty(fetchedData.value));

// Request stat
const fetch = async () => {
    try {
        fetchedData.value = (await axios.get('owner/cashbox/stats')).data;
    } catch (e) {
        console.error(e);
    }
}

// flag first requesy on demand
let isFetched = false;

const transfer = async () => {
    try {
        let result = await axios.post('owner/cashbox/transfer');
        // drop fetched data as transfer has success
        fetchedData.value = {};
        return Promise.resolve(result.data);
    } catch (e) {
        return Promise.reject(e);
    }
}

// Add amount without request to update stats data
const addAmount = function (amount) {
    fetchedData.value.summary = (fetchedData.value.summary || 0) + parseFloat(amount);
}

export default function useCashboxStatsComposition() {
    if (!isFetched) {
        fetch().then(() => isFetched = true);
    }
    return {
        fetchedData,
        hasActiveBox,
        fetch,
        transfer,
       addAmount
    }
}