/**
 * Try composition api with paytype selection
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import {ref} from "vue";
import axios from "axios";

const isLoading = ref(false);

const fetchDetails = (token) => {
    isLoading.value = true;
    return axios.get(`owner/order/${token}`)
        .then(response => response.data)
        .finally(() => isLoading.value = false);
}

const handover = (order) => {
    isLoading.value = true;
    return axios.post(`owner/order/${order.access_token}/handover`)
        .finally(() => isLoading.value = false);
}

const cancel = (order) => {
    isLoading.value = true;
    return axios.post(`owner/order/${order.access_token}/cancel`)
        .finally(() => isLoading.value = false);
}

const income = (order, data) => {
    isLoading.value = true;
    return axios.post(`owner/order/${order.access_token}/income`, data)
        .then(response => response.data)
        .finally(() => isLoading.value = false);
}

export default function useOrderApiComposition() {
    return {
        isLoading,
        fetchDetails,
        handover,
        cancel,
        income
    }
}