/**
 * Class to handle swipe events on element
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 *
 * @see https://stackoverflow.com/questions/2264072/detect-a-finger-swipe-through-javascript-on-the-iphone-and-android
 * @see https://github.com/john-doherty/swiped-events
 */
export const SWIPE_UP = 'up';
export const SWIPE_DOWN = 'down';
export const SWIPE_LEFT = 'left';
export const SWIPE_RIGHT = 'right';

function SwipeEvents(el, config) {
    let xDown = null;
    let yDown = null;
    let xDiff = null;
    let yDiff = null;
    let swipeThreshold = config && config.threshold && parseInt(config.threshold, 10) || 20; // default 20px
    let callback = config && typeof config.callback === 'function' && config.callback
        || function (e) {
            console.warn("SwipeEvents callback doesnt provide", e);
        };
    let direction;


    el.addEventListener('touchstart', handleTouchStart, false);
    el.addEventListener('touchmove', handleTouchMove, false);
    el.addEventListener('touchend', handleTouchEnd, false);


    /**
     * Records current location on touchstart event
     * @param {object} e - browser event object
     * @returns {void}
     */
    function handleTouchStart(e) {
        xDown = e.touches[0].clientX;
        yDown = e.touches[0].clientY;
        xDiff = 0;
        yDiff = 0;
    }


    /**
     * @return null|string
     * */
    function getDirection(xDiff, yDiff, threshold) {
        if (Math.abs(xDiff) > Math.abs(yDiff)) { // most significant
            if (Math.abs(xDiff) > threshold) {
                if (xDiff > 0) {
                    return SWIPE_LEFT;
                } else {
                    return SWIPE_RIGHT;
                }
            }
        } else if (Math.abs(yDiff) > threshold) {
            if (yDiff > 0) {
                return SWIPE_UP;
            } else {
                return SWIPE_DOWN;
            }
        }
        return null;
    }


    /**
     * Records location diff in px on touchmove event
     * @param {object} e - browser event object
     * @returns {void}
     */
    function handleTouchMove(e) {
        if (!xDown || !yDown) return;

        let xUp = e.touches[0].clientX;
        let yUp = e.touches[0].clientY;
        let changedTouches = e.changedTouches || e.touches || [];

        xDiff = xDown - xUp;
        yDiff = yDown - yUp;
        if (null !== (direction = getDirection(xDiff, yDiff, swipeThreshold))) {
            let eventData = {
                direction,
                type: e.type,
                xStart: parseInt(xDown, 10),
                xEnd: parseInt((changedTouches[0] || {}).clientX || -1, 10),
                yStart: parseInt(yDown, 10),
                yEnd: parseInt((changedTouches[0] || {}).clientY || -1, 10)
            };
            callback(eventData);
        }
    }


    /**
     * Fires swiped event if swipe detected on touchend
     * @param {object} e - browser event object
     * @returns {void}
     */
    function handleTouchEnd(e) {
        const changedTouches = e.changedTouches || [];
        const eventData = {
            direction,
            type: e.type,
            xStart: parseInt(xDown, 10),
            xEnd: parseInt((changedTouches[0] || {}).clientX || -1, 10),
            yStart: parseInt(yDown, 10),
            yEnd: parseInt((changedTouches[0] || {}).clientY || -1, 10)
        };
        callback(eventData);

        // reset values
        xDown = null;
        yDown = null;
    }


    /**
     * Manually remove events
     * */
    this.destroyEvents = () => {
        el.removeEventListener('touchstart', handleTouchStart, false);
        el.removeEventListener('touchmove', handleTouchMove, false);
        el.removeEventListener('touchend', handleTouchEnd, false);
    }
}

// export the factory
export default SwipeEvents;