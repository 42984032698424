<script>
import useOrderApiComposition from "@/views/order/OrderApiComposition";
import {computed, ref, watchEffect} from "vue";
import useCashboxStatsComposition from "@/views/cashbox/StatsComposition";

/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
export default {
  name: "PayForm",
  props: {
    order: {
      type: Object,
      require: true
    },
    paytype: {
      type: String
    }
  },
  setup(props, context) {
    const form = ref({
      paytype: props.paytype,
      amount: 500,
      comments: null
    });
    const {addAmount} = useCashboxStatsComposition();
    const {income, isLoading} = useOrderApiComposition();
    const title = computed(() => props.order.paytypes && props.order.paytypes[props.paytype] || '');

    watchEffect(() => {
      form.value.amount = props.order.amount;
    });

    return {income, isLoading, title, form, addAmount};
  },
  methods: {
    onCancelClick() {
      this.$router.back();
    },
    onSubmitClick(e) {
      this.income(this.order, this.form)
          .then(data => {
            this.$message.success('Платеж успешно зарегистрирован');
            this.addAmount(data.amount);
            this.$emit('orderupdate');
            this.onCancelClick();
          })
          .catch();
    }
  }
}
</script>


<template>
  <div class="payform">
    <h3>{{ title }}</h3>
    <el-form label-position="top"
             class="form"
             :model="form">

      <el-form-item class="form__input"
                    label="Сумма, руб">
        <el-input-number v-model="form.amount"
                         :step="500"
                         :min="0"
                         :max="order.amount"></el-input-number>
      </el-form-item>
      <el-form-item class="form__input"
                    label="Комментарий">
        <el-input v-model="form.comments"
                  type="textarea"
                  maxlength="250"></el-input>
      </el-form-item>
    </el-form>

    <el-button type="primary"
               class="button"
               icon="el-icon-money"
               @click.stop="onSubmitClick">Внести платеж
    </el-button>
    <el-button type="danger"
               class="button"
               @click.stop="onCancelClick">Отменить
    </el-button>
  </div>
</template>


<style lang="scss" scoped>
@import "../../scss/vars";

.payform {
  padding: 0 $default-gap;
}

.form {
  margin: $default-gap 0;

  &__input {
    width: 100%;
  }

  label {
    line-height: 1;
  }
}

.button {
  width: 100%;

  & + & {
    margin: $default-gap 0;
  }
}
</style>