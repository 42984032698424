<script>
/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */


export default {
  name: "CallLink",
  props: {
    tel: {
      required: true
    },
    text: {
      type: String
    }
  },
  computed: {
    href: vm => vm.tel && vm.$Formatter.asTelLink(vm.tel)
  }
}
</script>


<template>
  <div class="holder">
    <div class="holder__icon">
      <el-icon class="el-icon-phone-outline"></el-icon>
    </div>
    <div class="holder__caption">
      <a class="phone-link"
         :href="href"
         @click.stop
         v-bind="$attrs">{{ text || tel }}</a>
    </div>
  </div>
</template>


<style scoped lang="scss">
@import "../scss/vars";

.holder {
  display: inline-flex;
  align-items: center;

  &__icon {
    flex: 0 0 24px;
    font-size: 18px;
    text-align: left;
  }

  &__caption {
    margin-left: $default-gap/2;
    color: $color-primary;
  }
}


.phone-link {
  text-decoration: none;
  border-bottom: 1px dashed;
  border-color: inherit;
  white-space: nowrap;
  color: $color-primary;
  text-overflow: ellipsis;
}
</style>