<script>
/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */


export default {
  name: "GeoLink",
  props: {
    address: {
      required: true
    },
    text: {
      type: String
    }
  },
  computed: {
    href: vm => `yandexnavi://map_search?text=${vm.address}`
  }
}
</script>


<template>
  <div class="holder">
    <div class="holder__icon">
      <img src="../assets/yanavigator.png" class="holder__img"/>
    </div>
    <div class="holder__caption">
      <a class="holder__link"
         :href="href"
         @click.stop
         v-bind="$attrs">{{ text || address }}</a>
    </div>
  </div>
</template>


<style scoped lang="scss">
@import "../scss/vars";

.holder {
  display: inline-flex;
  align-items: center;
  padding: $default-gap/2 0;
  line-height: 1;

  &__icon {
    flex: 0 0 24px;
    text-align: left;
  }

  &__img {
    width: 24px;
    height: 24px;
  }

  &__caption {
    margin-left: $default-gap/2;
    color: $color-primary;
  }

  &__link {
    text-decoration: none;
    color: $color-primary;
    border-bottom: 1px dashed;
    border-color: inherit;
  }
}

</style>