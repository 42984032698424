/**
 * Apply router setup
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import router from "./index";
import Login from "../views/Login";
import {isLogged, tryAutoLogin} from "@/views/account/UserComposition";
import {ROUTE_LOGIN, ROUTE_LIST} from "@/router/routes_names";

router.addRoute(
    {
        path: '/login',
        name: ROUTE_LOGIN,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Login
    }
);


let ttl = 6;
const trace = function (msg) {
    if (process.env.NODE_ENV === 'development') {
        console.info.apply(this, arguments);
    }
}

// add hook
router.beforeEach((to, from, next) => {
    // Не авторизованы
    if (!isLogged.value) {
        trace("Не авторизованы", to);

        if (!ttl--) {
            throw new Error("Ttl redirection exceeded");
        }
        if (to.name === ROUTE_LOGIN) {
            trace("Запрошенный маршрут форма авторизации");
            next();
            return;
        }

        // Пробуем залогиниться автоматически
        tryAutoLogin()
            .then(() => {
                trace("Авторизовались, идем дальше");
                next()
            })
            .catch((e) => {
                trace("Не залогинились", e);
                // Идем на форму авторизации
                next({name: ROUTE_LOGIN});
            });
    }
    // Авторизованы
    else {
        trace("Авторизованы", to);
        // Запрошенный маршрут форма авторизации
        if (to.name === ROUTE_LOGIN) {
            trace("Запрошенный маршрут форма авторизации, переходим на главную");
            // Переходим на главную
            next({name: ROUTE_LIST})
        } else {
            trace("Выполнено");
            // Идем дальше
            next()
        }
    }
});