<script>
/**
 * Info about logged user
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import useUserComposition from "@/views/account/UserComposition";
import {ROUTE_LOGIN} from "@/router/routes_names";
import {ref, watchEffect} from "vue";
import SessionList from "@/views/account/SessionList";

export default {
  name: "UserInfo",
  components: {SessionList},
  setup() {
    const userComposition = useUserComposition();
    const sessions = ref([]);
    const isPaneOpened = ref(false);
    const fetchSessions = function () {
      userComposition.fetchSessions().then(data => {
        sessions.value = data.filter(i => i.id !== userComposition.user.value.id);
      });
    }

    watchEffect(() => {
      if (isPaneOpened.value) {
        fetchSessions();
      }
    });

    return {
      isPaneOpened,
      sessions,
      user: userComposition.user,
      logout: userComposition.logout,
      fetchSessions
    }
  },

  methods: {
    onLogoutClick: function () {
      this.logout()
          .then(() => {
            this.$router.push({name: ROUTE_LOGIN});
          });
    },
    onSessionDelete(item) {
      this.fetchSessions();
    }
  }
}
</script>


<template>
  <el-button type="text"
             class="userinfo"
             icon="el-icon-user-solid"
             @click="isPaneOpened=true">
    <span class="userinfo__text">{{ user.username }}</span>
  </el-button>

  <el-drawer v-model="isPaneOpened"
             append-to-body
             size="95%"
             :title="user.fullname"
             direction="rtl">
    <el-menu>
      <el-menu-item>
        <el-icon class="el-icon-user"></el-icon>
        {{ user.username }}
      </el-menu-item>
      <el-menu-item>
        <el-icon class="el-icon-message"></el-icon>
        {{ user.email }}
      </el-menu-item>
      <el-menu-item @click="onLogoutClick">
        <el-icon class="el-icon-switch-button"></el-icon>
        Выход
      </el-menu-item>
    </el-menu>

    <hr/>
    <session-list :store="sessions" @itemdelete="onSessionDelete"></session-list>
  </el-drawer>
</template>


<style lang="scss" scoped>
.userinfo {
  display: flex;
  align-items: center;

  &__icon {
    flex: 0 0 auto;
  }

  &__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>