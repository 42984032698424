import "./plugins/axios"
import "./plugins/moment"
import "./plugins/element"
import "./router"
import "./router/login"
import "./scss/main.scss"
import app from "./app"
import './registerServiceWorker'

// mount app after all plugins`s "use" calls
app.mount(document.body);