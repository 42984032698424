<script>
/**
 * The stats view
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
import useCashboxStatsComposition from "@/views/cashbox/StatsComposition";
import useApiComposition from "@/views/cashbox/ApiComposition";

export default {
  name: "Stats",
  setup() {
    const {hasActiveBox, fetchedData, fetch, transfer} = useCashboxStatsComposition();
    const {fetch: fetchList} = useApiComposition();
    //fetch during setup
    fetch();

    return {
      hasActiveBox,
      fetchedData,
      transfer,
      fetchList
    }
  },
  methods: {
    async onSubmitClick() {
      try {
        await this.$confirm('Текущая статистика по кассе будет обнулена. Продолжить?',
            'Подтвердить действие',
            {
              cancelButtonText: 'Отмена',
              confirmButtonText: 'Продолжить'
            });
        await this.transfer();
        // emit to parent data has been changed
        this.$emit("transfered");
      } catch (e) {
        console.error(e);
      }
    }
  }
}
</script>


<template>
  <div class="stats">
    <h3>Касса</h3>
    <div v-if="hasActiveBox">
      <!--      <p class="stats__date">{{ $Formatter.asRelativeTime(fetchedData.updated_at) }}</p>-->
      <ul class="statslist">
        <li v-for="paytype in fetchedData.stats"
            class="statslist__item">
          <span class="statslist__label">{{ paytype.type }}</span>
          <span class="statslist__price">{{ $Formatter.asCurrency(paytype.sum_total) }}</span>
        </li>
      </ul>

      <el-button type="info"
                 icon="el-icon-sold-out"
                 class="submit-btn"
                 @click="onSubmitClick">Сдать кассу
      </el-button>
    </div>
    <p v-else class="empty-text">Сейчас она пуста, в ней автоматически появятся данные при первом платеже...</p>
  </div>
</template>


<style scoped lang="scss">
@import "/src/scss/vars.scss";

.stats {
  padding: 0 $default-gap;

  &__date {
    font: italic 14px/1.2 $font-family-base;
    color: $color-notes;
  }
}

.statslist {
  display: flex;
  align-items: flex-end;
  list-style: none;
  padding: 0;
  width: 100%;

  &__item {
    flex: 0 0 auto;
    text-align: center;
    padding: 0 $default-gap/2;
    //border: 1px solid silver;
    width: 33%;
  }

  &__label {
    font: 12px/1 $font-family-base;
  }

  &__price {
    display: block;
    white-space: nowrap;
    font: bold 18px/2 $font-family-base;
  }
}

.submit-btn {
  margin-top: 2*$default-gap;
  width: 100%;
}
</style>