/**
 * Provide methods to detect device usage
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */

export default {
    isMobile() {
        return Math.max(window.innerWidth, window.innerHeight) <= 1280;
    },
    isDesktop() {
        return !this.isMobile();
    },
    isLandscape() {
        if (screen && screen.orientation) {
            return ['landscape-primary', 'landscape', 'landscape-secondary'].indexOf(screen.orientation.type) > -1;
        }
        return window.innerHeight < window.innerWidth
    },
    isPortrait() {
        return !this.isLandscape();
    },
}