<script>
import useOrderComposition from "@/views/order/OrderComposition";
import {computed, watchEffect} from "vue";
import {ROUTE_LIST} from "@/router/routes_names";
import StickyHeader from "@/components/StickyHeader";
import useOrderApiComposition from "@/views/order/OrderApiComposition";

/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */


export default {
  name: "OrderLayout",
  components: {StickyHeader},
  props: {
    token: {
      type: String,
      require: true
    }
  },
  setup(props) {
    const {
      order,
      isDeliverable,
      isDelivered,
    } = useOrderComposition()

    const {
      isLoading,
      fetchDetails
    } = useOrderApiComposition()

    watchEffect(() => {
      fetchDetails(props.token).then(data => order.value = data);
    });

    const paytypes = computed(() => order.value.paytypes);

    return {
      order,
      paytypes,
      isDeliverable,
      isDelivered,
      isLoading,
      fetchDetails
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        name: ROUTE_LIST
      })
    },

    // handle order update event from view
    onOrderUpdateRequired() {
      console.log(this.order);
      this.fetchDetails(this.token).then(data => this.order = data);
    }
  }
}
</script>


<template>
  <div v-loading="isLoading">
    <sticky-header class="header">
      <el-page-header @back="goBack"
                      title=""
                      v-bind="$attrs">
        <template v-slot:content>
          <span class="number">#{{ order.number }},</span>&nbsp;
          <span class="date">{{ $Formatter.asDate(order.delivery_date) }}</span>
        </template>
      </el-page-header>
    </sticky-header>
    <router-view :order="order" v-bind="$props" @orderupdate="onOrderUpdateRequired"></router-view>
  </div>
</template>


<style lang="scss" scoped>
@import "../../scss/vars";

.number {
  font: bold 18px/1 $font-family-base;
}

.date {
  color: gray;
  font: italic 16px/1 $font-family-base;
}

.header {
  padding-bottom: $default-gap;
}
</style>