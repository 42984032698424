import {createRouter, createWebHashHistory} from 'vue-router'
import PrimaryLayout from "@/layouts/PrimaryLayout";
import OrderList from "@/views/orderlist/List";
import OrderDetails from "@/views/order/DetailsView";
import OrderLayout from "@/views/order/Layout"
import PayForm from "@/views/order/PayForm";
import QrMain from "@/views/qr/Main";
import DeliveryForm from "@/views/order/DeliveryForm";
import CashboxLayout from "@/views/cashbox/Layout";
import {
    ROUTE_CANCEL,
    ROUTE_CASHBOX,
    ROUTE_DELIVERY,
    ROUTE_DETAILS,
    ROUTE_HOME_PATH,
    ROUTE_LIST,
    ROUTE_PAYMENT,
    ROUTE_QR
} from "@/router/routes_names";
import AppTabs from "@/layouts/AppTabs";
import {TAB_CASHBOX, TAB_ORDERS} from "@/layouts/AppTabsComposition";


const routes = [
    {
        component: PrimaryLayout,
        path: '/',
        children: [
            {
                path: '',
                alias: '/orders',
                name: ROUTE_LIST,
                components: {
                    default: OrderList,
                    footer: AppTabs
                },
                props: {
                    footer: {
                        activeTab: TAB_ORDERS
                    }
                }
            },
            {
                path: '/qr',
                name: ROUTE_QR,
                components: {
                    default: QrMain,
                    footer: AppTabs
                },
                props: {
                    footer: {
                        activeTab: TAB_ORDERS
                    }
                }
            },
            {
                component: OrderLayout,
                path: '/order/:token',
                props: true,
                children: [
                    {
                        path: '',
                        name: ROUTE_DETAILS,
                        component: OrderDetails
                    },
                    {
                        path: ':paytype',
                        props: true,
                        name: ROUTE_PAYMENT,
                        component: PayForm
                    },
                    {
                        path: 'handover',
                        props: (route) => ({isHandover: true, ...route.params}),
                        name: ROUTE_DELIVERY,
                        component: DeliveryForm
                    },
                    {
                        path: 'cancel',
                        props: (route) => ({isHandover: false, ...route.params}),
                        name: ROUTE_CANCEL,
                        component: DeliveryForm
                    },
                ]
            },
            {
                path: '/cashbox',
                name: ROUTE_CASHBOX,
                components: {
                    default: CashboxLayout,
                    footer: AppTabs
                },
                props: {
                    footer: {
                        activeTab: TAB_CASHBOX
                    }
                }
            },
        ]
    },

    /* Default redirect */
    {path: '/:pathMatch(.*)*', redirect: {path: ROUTE_HOME_PATH}}
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
