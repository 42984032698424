/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import {ROUTE_CASHBOX, ROUTE_LIST} from "@/router/routes_names";
import {computed, ref} from "vue";
import useCashboxStatsComposition from "@/views/cashbox/StatsComposition";
import Formatter from "@/utils/Formatter";

export const TAB_ORDERS = 'orders';
export const TAB_CASHBOX = 'cashbox';

const tabs = {
    [TAB_ORDERS]: {
        title: 'Заказы',
        iconCls: 'el-icon-truck',
        route: {name: ROUTE_LIST}
    },
    [TAB_CASHBOX]: {
        title: 'Касса',
        iconCls: 'el-icon-money',
        route: {name: ROUTE_CASHBOX}
    }
}

const activeTab = ref(TAB_ORDERS);

export default function useAppTabsComposition() {
    const {fetchedData, hasActiveBox} = useCashboxStatsComposition();
    const calculatedTabs = computed(() => {
        tabs[TAB_CASHBOX].badge = hasActiveBox.value && Formatter.asCurrency(fetchedData.value.summary) || null;
        return tabs;
    });

    return {
        tabs,
        calculatedTabs,
        activeTab
    }
}