<script>
/**
 * Primary app tabs
 *
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
import useAppTabsComposition from "@/layouts/AppTabsComposition";
import {watchEffect} from "vue";

export default {
  name: "AppTabs",
  props: {
    activeTab: {
      type: String
    }
  },
  setup(props) {
    const {tabs, activeTab, calculatedTabs} = useAppTabsComposition();

    // watch any change of activeTab
    watchEffect(() => {
      activeTab.value = props.activeTab;
    });

    return {
      tabs: calculatedTabs,
      activeTab
    }
  },
  methods: {
    onTabClick(tab, index) {
      if (tab.route) {
        this.$router.push(tab.route);
      }
    }
  }
}
</script>


<template>
  <div class="tabspanel">
    <div v-for="(tab,id) in tabs"
         class="tabspanel__tab tab" :class="{'tab--active':id ===activeTab}"
         role="button"
         @click.stop="onTabClick(tab,id)">
      <div class="tab__inner">
        <el-icon :class="tab.iconCls" class="tab__icon"></el-icon>
        <span class="tab__title">{{ tab.title }}</span>
        <el-badge class="tab__badge" :max="0" v-if="tab.badge" :value="tab.badge"></el-badge>
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">
@import "../scss/vars";

.tabspanel {
  display: flex;
  align-items: stretch;
  height: 80px;
  justify-content: space-around;

  &__tab {
    flex: 0 0 50%;
  }
}

.tab {
  text-align: center;
  opacity: .5;
  //padding: 5px 0 0 0;
  color: $color-primary;

  &__inner {
    display: inline-block;
    position: relative;
    // make content vertical centered
    top: 50%;
    transform: translateY(-50%);
  }

  &__icon {
    font-size: 28px;
    font-weight: inherit;
    display: block;
  }

  &__badge {
    top: -10px;
    left: 60%;
    position: absolute;
  }

  &--active {
    opacity: 1;
    font-weight: bolder;
  }
}
</style>