<script>
import CashboxList from "./List";
import CashboxStats from "./Stats";
import TooltipPanel from "@/components/TooltipPanel";
import useApiComposition, {STATUS_ACTIVE} from "@/views/cashbox/ApiComposition";
import {computed, ref} from "vue";
import {find, reverse, sortBy, without} from "lodash";
import CashboxListItem from "@/views/cashbox/ListItem";

/**
 * Main layout
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
export default {
    name: "Layout",
    components: {CashboxListItem, TooltipPanel, CashboxList, CashboxStats},
    setup() {
        const {isFetching, fetch} = useApiComposition();
        const store = ref([]);
        const sortedStore = computed(() => {
            let collection = [...store.value];
            const active = find(collection, {status: STATUS_ACTIVE});
            let sortedByDate = reverse(sortBy(collection, 'updated_at'));
            if (active) {
                return [active, ...without(sortedByDate, active)];
            }
            return sortedByDate;
        });

        return {
            fetch,
            store,
            sortedStore,
            isFetching
        }
    },
    methods: {
        updateData() {
            this.fetch().then((data) => this.store = data);
        }
    },
    mounted() {
        this.updateData();
    }
}
</script>


<template>
    <cashbox-stats @transfered="updateData"></cashbox-stats>
    <tooltip-panel expanded-height="90%"
                   v-loading="isFetching">
        <template #default>
            <cashbox-list-item :item="sortedStore[0] || {}"></cashbox-list-item>
        </template>
        <template #full>
            <cashbox-list :store="sortedStore"></cashbox-list>
        </template>
    </tooltip-panel>
</template>


<style scoped>

</style>