<script>/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
import DeviceInfo from "@/utils/DeviceInfo";


export default {
  name: "Viewport",
  methods: {
    applyHeight(e) {
      if (!this.viewport) {
        this.viewport = this.$refs.viewport;
      }

      // when [e] present mean orientationchange event happen
      // ширина и высота наоборот потому что они содержат значения до переворота
      const height = e && e.target.innerWidth || window.innerHeight
      const width = e && e.target.innerHeight || window.innerWidth

      if (DeviceInfo.isPortrait()) {
        this.viewport.style.height = `${height}px`;
        this.viewport.style.width = `${width}px`;
      } else {
        this.viewport.style.height = `${width}px`;
        this.viewport.style.width = `${height}px`;
      }
    }
  },
  /*mounted() {
    // hardcode height
    this.applyHeight();
    window.addEventListener('orientationchange', this.applyHeight);
  },
  unmounted() {
    window.removeEventListener('orientationchange', this.applyHeight);
  }*/
}
</script>


<template>
  <div id="viewport" ref="viewport">
    <router-view/>
  </div>
</template>


<style lang="scss">
@import "/src/scss/mixin/layout";

#viewport {
  @include layout-fit();
  // css fallback
  /*@media (orientation: landscape) {
    transform: rotate(-90deg);
    max-height: 100vh;
    overflow: hidden;
  }*/
}
</style>