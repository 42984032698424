import {ref} from "vue";
import axios from "axios";

/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
export const STATUS_ACTIVE = 'active';
export const STATUS_TRANSFER = 'transfer';

export default function useApiComposition() {
    const isFetching = ref(false);

    // Fetch the data
    const fetch = async () => {
        if (isFetching.value) {
            console.warn('Fetching cashbox already busy');
            return;
        }
        try {
            isFetching.value = true;
            const fetchedData = await axios.get('owner/cashbox');
            return fetchedData.data;
        } catch (e) {
            console.error(e);
        } finally {
            isFetching.value = false;
        }
    }


    return {
        isFetching,
        fetch
    }
}