<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import useUserComposition from "@/views/account/UserComposition";
import {ROUTE_HOME_PATH} from "@/router/routes_names";

export default {
    name: "Login",
    data() {
        return {
            validationModel: {
                username: "",
                password: ""
            },
            rules: {
                username: {required: true, message: 'Необходимо заполнить'},
                password: {required: true, message: 'Необходимо заполнить'}
            }
        }
    },

    setup() {
        const userComposition = useUserComposition();
        return {
            isLoading: userComposition.isLoading,
            loginByCredentials: userComposition.loginByCredentials
        }
    },

    methods: {
        onLoginClick() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.loginByCredentials(this.validationModel)
                        .then(() => {
                            this.$message.success("Успешный вход");
                            this.$router.push(ROUTE_HOME_PATH);
                        });
                }
            })
        }
    }
}
</script>


<template>
    <el-form :model="validationModel" ref="form" :rules="rules">
        <div class="logo">
            <img src="../assets/iloranta_courier.svg" vspace="40">
        </div>
        <el-form-item label="Логин"
                      required
                      prop="username">
            <el-input :maxlength="32"
                      clearable
                      v-model="validationModel.username"></el-input>
        </el-form-item>
        <el-form-item label="Пароль"
                      required
                      prop="password">
            <el-input :maxlength="32"
                      show-password
                      clearable
                      v-model="validationModel.password"></el-input>
        </el-form-item>
        <el-button :loading="isLoading"
                   type="primary"
                   @click="onLoginClick">Вперед
        </el-button>
    </el-form>

    <p class="developed">Разработано <code>ООО "СайтСпринг"</code>. <br>
        Техническая поддержка <a href="mailto:support@sitespring.ru">support@sitespring.ru</a>
    </p>

</template>


<style lang="scss" scoped>

.el-form {
    max-width: 240px;
    margin: auto;
}

img {
    max-width: 100%;
}


.developed {
    width: 100%;
    font-size: 11px;
    color: gray;
    margin: 0;
    text-align: center;
    padding: 20px 0;

    @media (orientation: portrait) {
        position: fixed;
        bottom: 0;
    }
}
</style>