<script>
/**
 * Separate component to display row of actions
 *
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
export default {
  name: "ActionsList",
  props: {
    /*
    * The default value will be applied when no width provide
    * */
    defaultWidth: {
      type: Number,
      default: 60
    },
    /* The actions definition list as the follows
  * [{
        text: 'Действие 1',
        iconCls: 'el-icon-close',
        ui: 'primary',
        handler: () => alert('click 1')
      },
        {
          width:60,
          text: 'Действие 2',
          iconCls: 'el-icon-close',
          ui: 'danger',
          handler: () => alert('click 2')
        }]
  * */
    actions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    onActionClick(btn) {
      if (typeof btn.handler == 'function') {
        btn.handler(btn);
      }
    }
  }
}
</script>


<template>
  <div class="actions">
    <div v-for="(btn,index) in actions"
         class="actions__btn btn"
         role="button"
         :class="`btn--${btn.ui}`"
         @touchstart.prevent="onActionClick(btn)"
         @click="onActionClick(btn)">
      <div class="btn__content"
           :style="{width: `${btn.width||defaultWidth}px`}">
        <span class="btn__icon">
          <i :class="btn.iconCls"></i>
        </span>
        <span class="btn__text">{{ btn.text }}</span>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
@import "/src/scss/vars.scss";

.actions {
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;

  &__btn {
    flex: 1;
  }
}

.btn {
  text-align: center;
  // defaults
  background: $color-info;
  color: #000000;
  position: relative;

  &__content {
    // make content centered
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &__icon {
    font-size: 32px;
    display: block;
  }

  &__text {
    display: inline-block;
    font: 12px/1.1 $font-family-base;
  }

  &--primary {
    background: $color-primary;
    color: #ffffff;
  }

  &--danger {
    background: $color-danger;
    color: #ffffff;
  }
}
</style>