/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
export const ROUTE_LOGIN = 'login';
export const ROUTE_HOME_PATH = process.env.VUE_APP_HOME_URL_NAME;

export const ROUTE_LIST = 'orders';
export const ROUTE_DETAILS = 'details';
export const ROUTE_PAYMENT = 'pay';
export const ROUTE_DELIVERY = 'delivery';
export const ROUTE_CANCEL = 'cancel';
export const ROUTE_QR = 'qr';

export const ROUTE_CASHBOX = 'cashbox';
export const ROUTE_CASHBOX_DETAILS = 'cashbox-details';