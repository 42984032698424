<script>
import CallLink from "@/components/CallLink";
import GeoLink from "@/components/GeoLink";
import SwipeActionsListItem from "@/components/SwipeActionsListItem";
import {ROUTE_DETAILS} from "@/router/routes_names";

/**
 * Shared component to display order list item view
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 *
 * Assume order fetched with following fields:
 *  fetchParams:{
 *      fields: 'id,number,delivery_date,manager_notes,total,client_name,client_phone',
 *      expand: 'statusText,payStatusText,counterpartyName'
 *  }
 */
export default {
  name: "OrderListItem",
  components: {SwipeActionsListItem, GeoLink, CallLink},
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      actionsLeft: [
        {
          iconCls: 'el-icon-discover',
          text: "Яндекс навигатор",
          handler: this.openYaNavigator,
          width: 80
        }, {
          iconCls: 'el-icon-phone-outline',
          text: 'Набрать номер',
          handler: this.callToClick,
          width: 80,
          ui: 'primary'
        }
      ],
      actionsRight: [
        {
          iconCls: 'el-icon-right',
          text: 'Открыть',
          handler: this.onItemExpand,
          width: 120
        }
      ],
      primaryActionRight: {
        iconCls: 'el-icon-right',
        text: 'Отпустите чтобы открыть...',
        handler: this.onItemExpand,
        ui: 'primary',
        width: 170
      }
    }
  },
  computed: {
    amount: vm => parseFloat(vm.order.amount) - parseFloat(vm.order.awaiting || 0)
  },
  methods: {
    callToClick() {
      window.location.href = `tel:${this.order.client_phone}`;
    },
    openYaNavigator() {
      window.location.href = `yandexnavi://map_search?text=${this.order.delivery_address}`;
    },
    onItemExpand() {
      this.$router.push({name: ROUTE_DETAILS, params: {token: this.order.access_token}});
    },
  }
}
</script>


<template>
  <swipe-actions-list-item :actions-left="actionsLeft"
                           :actions-right="actionsRight"
                           :primary-action-right="primaryActionRight">
    <div class="item" @click="onItemExpand">
      <div class="item__content">
        <header class="item__header">
          <span class="number">#{{ order.number }}</span>
          <span class="amount">{{ amount > 0 && ` +${$Formatter.asCurrency(amount)}` || null }}</span>
        </header>

        <div class="delivery">
          <address class="address">
            {{ order.delivery_address }}
          </address>
        </div>
        <span class="date">{{ $Formatter.asRelativeTime(order.delivery_date) }}</span>
      </div>
      <!--      <div class="item__trigger" @click="onExpandClick">
              <el-icon class="el-icon-right"></el-icon>
            </div>-->
    </div>
  </swipe-actions-list-item>


</template>


<style lang="scss" scoped>
@import "../../scss/vars";

.item {
  display: flex;
  align-items: center;
  width: 100%;
  margin: $default-gap 0;
  padding: 0 $default-gap;

  & + & {
    border-top: 1px solid $color-borders;
    padding-top: $default-gap;
  }

  &__dropdown-trigger {
    display: inline-block;
    margin: 0 $default-gap/2 0 0;
  }

  &__content {
    flex: 0 0 auto;
    // restrict content to allow  text-overflow: ellipsis works
    max-width: 100%;
  }

  &__trigger {
    font-size: 28px;
    flex: 0 0 auto;
  }
}

.number {
  font: bold 16px/1 $font-family-base;
}

.amount {
  white-space: nowrap;
  font: italic 14px/1.5 $font-family-base;
  color: $color-danger;
}

.address {
  font: italic 16px/1.2 $font-family-base;
  color: $color-primary;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.client {
  font: 14px/1.5 $font-family-base;
}

.date {
  font: 12px/1.2 $font-family-base;
  color: $color-notes;
}
</style>