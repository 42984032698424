<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import UserInfo from "@/views/account/UserInfo";

export let MenuItems = [];

export default {
  name: "AppHeader",

  components: {
    UserInfo
  },

  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      items: MenuItems,
      isVisible: false
    }
  }
}
</script>


<template>
  <div class="layout">
    <div class="layout__logo logo">
      <img class="logo__image" :alt="`${title} logo`" src="../assets/iloranta_courier.svg">
    </div>
    <div class="layout__userinfo">
      <user-info></user-info>
    </div>
  </div>
</template>


<style lang="scss" scoped>
@import "../scss/vars";

.layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height;

  &__logo {
    height: 40px;
    flex: 0 0 40%;
  }

  .logo__image {
    max-height: 100%;
  }

  &__userinfo {
    flex: 0 0 auto;
    margin-left: $default-gap;
    text-align: right;
  }

}
</style>