<script xmlns="http://www.w3.org/1999/html">
/**
 * View details of cashbox income
 *
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
export default {
  name: "CashboxListItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    onExpandClick() {
      this.$emit('itemexpand', this.item);
    }
  }
}
</script>


<template>
  <div class="item" :class="[`item--${item.status}`]">
    <span class="item__price">{{ $Formatter.asCurrency(item.summary) }}</span>, <span class="item__status">{{ item.statusText }}</span>
    <span class="item__date">{{ $Formatter.asRelativeTime(item.updated_at) }}</span>
  </div>


</template>


<style lang="scss" scoped>
@import "../../scss/vars";

.item {
  padding-bottom: $default-gap/2;

  & + & {
    border-top: 1px solid $color-borders;
    padding-top: $default-gap/2;
  }

  &__price {
    white-space: nowrap;
    font: bold 20px/1.5 $font-family-base;
    color: $color-primary;
  }


  &__status {
    font: italic 14px/1.2 $font-family-base;
    color: $color-notes;
  }

  &__date {
    display: block;
    font: 12px/1.2 $font-family-base;
    color: $color-notes;
  }
}

.item--transfer {
  .item__price {
    color: $color-info;
  }
}
</style>