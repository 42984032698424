<template>
  <sticky-header>
    <el-page-header @back="goBack"
                    title=""
                    content="Поиск по QR-коду">
    </el-page-header>
  </sticky-header>
  <div id="stream">
    <qr-stream @decode="onDecode" class="mb">
      <div style="color: red;" class="frame"></div>
    </qr-stream>
  </div>
</template>


<script>
/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
import {ROUTE_DETAILS, ROUTE_LIST} from "@/router/routes_names";
import {QrStream} from "vue3-qr-reader";
import StickyHeader from "@/components/StickyHeader";

export default {
  name: "QrMain",
  components: {StickyHeader, QrStream},
  methods: {
    goBack() {
      this.$router.push({
        name: ROUTE_LIST
      })
    },
    onDecode(str) {
      try {
        const data = JSON.parse(str);
        const token = data.order && data.order.token || null;
        if (token) {
          this.$router.push({
            name: ROUTE_DETAILS,
            params: {
              token
            }
          })
        }
      } catch (e) {
        this.$message.error("QR-код неисвестен");
      }
    }
  }
}
</script>


<style lang="scss" scoped>
@import "../../scss/vars";

#stream {
  height: 250px;
  width: 250px;
  margin: $default-gap auto;
}

.frame {
  border-style: solid;
  border-width: 2px;
  border-color: red;
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
}
</style>