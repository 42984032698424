<script>
import AppHeader from "./AppHeader";
import AppTabs from "@/layouts/AppTabs";

/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
export default {
  name: "PrimaryLayout",
  components: {AppTabs, AppHeader}
}
</script>


<template>
  <el-container class="primary-layout">
    <el-header class="primary-layout__header">
      <app-header></app-header>
    </el-header>
    <el-main class="primary-layout__main" id="primary-layout__main">
      <router-view/>
    </el-main>
    <router-view class="primary-layout__footer"
                 name="footer"></router-view>
  </el-container>
</template>


<style lang="scss" scoped>
@import "/src/scss/vars";
@import "/src/scss/mixin/layout";

.primary-layout {
  @include layout-fit();

  &__header {
    -webkit-box-shadow: $light-top-shadow;
    -moz-box-shadow: $light-top-shadow;
    box-shadow: $light-top-shadow;
  }

  &__main {
    flex-grow: 1;
    padding: 0;
  }

  &__footer {
    // make footer over
    z-index: 100;
    -webkit-box-shadow: $light-bottom-shadow;
    -moz-box-shadow: $light-bottom-shadow;
    box-shadow: $light-bottom-shadow;
  }
}


</style>
