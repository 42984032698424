<script>
/**
 * Tooltip панелька с коротким контентом и при развертывании с полным контентом
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
export default {
  name: "TooltipPanel",
  props: {
    shortHeight: {
      default: 100
    },
    expandedHeight: {
      default: '80%'
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  watch: {
    isExpanded() {
      this.applyHeight();
    }
  },
  methods: {
    // Ensure parent is relative to correct positioning
    ensureParentStyles() {
      const parent = this.$refs.container.parentElement
      if (parent.style.position !== 'relative') {
        parent.style.position = 'relative';
      }
    },

    // Apply actual height
    applyHeight() {
      let height = this.isExpanded ? this.expandedHeight : this.shortHeight;
      if (Number.isInteger(height)) {
        height += 'px';
      }
      this.$refs.container.style.height = height;
    },
    onToggleContent() {
      this.isExpanded = !this.isExpanded;
    }
  },

  mounted() {
    this.ensureParentStyles();
    this.applyHeight();
  }
}
</script>


<template>
  <div class="tooltip-panel"
       :class="{'tooltip-panel--expanded':isExpanded}"
       ref="container">
    <header class="tooltip-panel__header header"
            @click="onToggleContent">
      <el-icon class="el-icon-arrow-up header__icon" v-if="!isExpanded"></el-icon>
      <el-icon class="el-icon-arrow-down header__icon" v-else></el-icon>
    </header>
    <main class="tooltip-panel__body">
      <slot name="default" v-if="!isExpanded || !$slots.full">
        <p>Short challenge content</p>
      </slot>
      <slot name="full" v-if="$slots.full && isExpanded"></slot>
    </main>
  </div>
</template>


<style scoped lang="scss">
@import "../scss/vars";
@import "/src/scss/mixin/layout";

.tooltip-panel {
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: all .2s ease-in-out;
  overflow: hidden;
  background: #fff;
  box-shadow: $light-bottom-shadow;
  z-index: 1;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  flex-flow: column;

  &__header {
    padding: 5px 10px;
    text-align: center;
  }

  &__body {
    padding: 0 $default-gap;
  }
}

.tooltip-panel--expanded {
  .tooltip-panel__body {
    // make scrolling on expanded
    overflow-x: auto;
  }
}

.header {
  &__icon {
    font-size: 32px;
    transform: scaleX(2);
    text-shadow: 1px 1px 2px $light-shadow-color;
  }
}
</style>