/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import {ref, computed} from "vue";
import axios from "axios";
import {setAuthToken} from "@/plugins/axios";

const defaults = {
    id: null,
    username: null,
    fullname: null,
    email: null,
    statusText: null,
    auth_token: null,
    session_id:null
};

const user = ref({...defaults});
const isLoading = ref(false);
export const isLogged = computed(() => !!user.value.auth_token);

const loginByCredentials = function ({username, password}) {
    const params = {username, password};
    isLoading.value = true;
    return axios.post('auth/login', params)
        .then((response) => {
            Object.assign(user.value, response.data);
            saveToken(response.data.auth_token)
        })
        .finally(() => isLoading.value = false);
}

const loginByToken = function (token) {
    const params = {token};
    isLoading.value = true;
    return axios.post('auth/login-by-token', params)
        .then((response) => {
            Object.assign(user.value, response.data);
            saveToken(response.data.auth_token);
            return response.data;
        })
        .finally(() => isLoading.value = false);
}

const logout = function () {
    isLoading.value = true;
    return deleteSession(user.value.session_id)
        .then((response) => {
            user.value = {...defaults};
            dropSavedToken();
        })
        .finally(() => isLoading.value = false);
}


const fetchSessions = function () {
    return axios.get(`user/owner/session`, {baseURL: process.env.VUE_APP_API_BASEURL})
        .then(response => response.data);
}

const deleteSession = function (id) {
    return axios.delete(`user/owner/session/${id}`, {baseURL: process.env.VUE_APP_API_BASEURL})
        .then(response => response.data);
}

export default function useUserComposition() {
    return {
        user,
        isLoading,
        isLogged,
        loginByCredentials,
        loginByToken,
        logout,
        fetchSessions,
        deleteSession
    }
}

//auto logging
const STORAGE_TOKEN_KEY = 'token';
const fetchSavedToken = function () {
    return localStorage.getItem(STORAGE_TOKEN_KEY) || null;
}
const saveToken = function (token) {
    setAuthToken(token);
    localStorage.setItem(STORAGE_TOKEN_KEY, token);
}
const dropSavedToken = function () {
    setAuthToken(null);
    localStorage.removeItem(STORAGE_TOKEN_KEY);
}

export const tryAutoLogin = function () {
    let token = fetchSavedToken();
    if (token) {
        return loginByToken(token);
    }
    return new Promise((resolve, reject) => {
        reject("Token not defined");
    });
}