/**
 * Adapt moment lib
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import moment from "moment";
import "moment/locale/ru";

moment.locale("ru");

export default moment;