/**
 * The app
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */

import {createApp} from "vue";
import Viewport from "./views/Viewport.vue";
import router from "./router";
import Formatter from "@/utils/Formatter";

const app = createApp(Viewport);
app.use(router);

// vue 3 glob props approach
app.config.globalProperties.$Formatter = Formatter;

export default app;