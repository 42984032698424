<script>
/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */


export default {
  name: "StickyHeader"
}
</script>


<template>
  <header class="sticky-header">
    <slot name="default"></slot>
  </header>
</template>


<style scoped lang="scss">
@import "../scss/vars";

.sticky-header {
  position: sticky;
  top: 0;
  background: #fff;
  padding: $default-gap/2 $default-gap;
  z-index: 1;

  -webkit-box-shadow: $light-top-shadow;
  -moz-box-shadow: $light-top-shadow;
  box-shadow: $light-top-shadow;
}
</style>
<style lang="scss">
// common for headers
.sticky-header {
  .el-icon-back {
    font-size: 24px;
  }
}
</style>