<script>
import {computed, ref} from "vue";
import useOrderApiComposition from "@/views/order/OrderApiComposition";

/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */


export default {
  name: "DeliveryForm",
  props: {
    order: {
      type: Object,
      require: true
    },
    isHandover: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {
        comments: null
      }
    }
  },
  setup(props) {
    const form = ref({
      comments: null
    });
    const {
      handover,
      cancel,
      isLoading
    } = useOrderApiComposition();
    const title = computed(() => props.isHandover ? 'Подтвердить доставку' : 'Отказ покупателя');

    return {form, handover, cancel, isLoading, title}
  },
  methods: {
    onCancelClick() {
      this.$router.back();
    },
    onSubmitClick(e) {
      if (this.isHandover) {
        this.handover(this.order, this.form).then(() => {
          this.$message.success('Ура! Еще один довольный покапатель!');
          this.$emit('orderupdate');
          this.onCancelClick();
        });
      } else {
        this.cancel(this.order, this.form).then(() => {
          this.$message.success('Заказ отменен покупателем!');
          this.$emit('orderupdate');
          this.onCancelClick();
        })
      }
    },
  }
}
</script>


<template>
  <div class="delivery-form">
    <h3>{{ title }}</h3>
    <el-form label-position="top"
             class="form"
             :model="form">
      <el-form-item class="form__input"
                    label="Комментарий">
        <el-input v-model="form.comments"
                  type="textarea"
                  :autosize="{minRows:3}"
                  show-word-limit
                  maxlength="100"></el-input>
      </el-form-item>
    </el-form>

    <el-button :type="isHandover ? 'primary' : 'info'"
               :loading="isLoading"
               class="button"
               :icon="isHandover ? 'el-icon-s-claim' : 'el-icon-s-release'"
               @click.stop="onSubmitClick">{{ title }}
    </el-button>
    <el-button type="danger"
               class="button"
               @click.stop="onCancelClick">Отменить
    </el-button>
  </div>
</template>


<style scoped lang="scss">
@import "../../scss/vars";

.delivery-form {
  padding: $default-gap;
}

.button {
  width: 100%;

  & + & {
    margin: $default-gap 0;
  }
}
</style>