<script>
/**
 * The orders list
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import OrderListItem from "./ListItem";
import OrderListSearch from "@/views/orderlist/Search";
import useOrderlistApiComposition from "@/views/orderlist/OrderListApiComposition";
import TopBar from "@/views/orderlist/TopBar";
import {provide, ref, watch} from "vue";
import {getScrollParent} from "@/utils/ScrollHelper"
import PullToRefresh from 'pulltorefreshjs';


export default {
    name: "OrderList",
    components: {
        TopBar,
        OrderListSearch,
        OrderListItem,
    },
    setup() {
        let {hasNextPage, isFetching, orders, queryText, fetchOrders, reloadOrders} = useOrderlistApiComposition();
        provide('queryText', queryText);
        return {
            hasNextPage,
            isFetching,
            orders,
            queryText,
            fetchOrders,
            reloadOrders
        };
    },
    watch: {
        queryText() {
            this.reloadOrders();
            this.$router.replace({query: {queryText: this.queryText}});
        }
    },
    computed: {
        emptyText: vm => vm.orders.length === 0 && 'Список заказов пуст...'
    },
    methods: {
        onRefresh: function () {
            console.log("onRefresh");
            this.reloadOrders();
        },
        onInfiniteScroll() {
            console.log("onInfiniteScroll");
            this.fetchOrders();
        }
    },
    created() {
        this.queryText = this.$route.query.queryText || '';
    },
    mounted() {
        const parentScroll = getScrollParent(this.$refs.list);
        this.ptr = PullToRefresh.init({
            mainElement: this.$refs.list,
            distIgnore: 20,
            onRefresh: this.onRefresh,
            instructionsReleaseToRefresh: 'Отпустите чтобы обновить',
            instructionsPullToRefresh: 'Тяните вниз чтобы обновить',
            instructionsRefreshing: 'Пожалуйста подождите...',
            shouldPullToRefresh: () => !parentScroll.scrollTop
        });
    },
    unmounted() {
        this.ptr.destroy();
    }
}
</script>


<template>
    <top-bar></top-bar>
    <div v-loading="isFetching"
         v-infinite-scroll="onInfiniteScroll"
         ref="list"
         :infinite-scroll-disabled="!hasNextPage">
        <order-list-item v-for="order in orders"
                         :key="order.number"
                         :order="order"></order-list-item>
    </div>
    <p v-if="emptyText"
       class="empty-text">{{ emptyText }}</p>

</template>

<style scoped lang="scss">

@import "../../scss/vars";

.empty-text {
    color: gray;
    font: italic 14px/3 $font-family-base;
    text-align: center;
}
</style>