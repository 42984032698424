<script>
    /**
     * @author Evgeny Shevtsov <info@sitespring.ru>
     * @homepage https://sitespring.ru
     * @licence Proprietary, Commercial
     */

    export default {
        name: "IconTrigger",
        props: {
            iconClass: {
                type: String,
                default: 'icon-option-delete'
            },
            ui: {
                type: String,
                default: 'default'
            },
            size: {
                type: String,
                default: 'medium'
            }
        },
        computed: {
            blockClass: vm => `icon-trigger-${vm.ui}`,
            modifierClass: vm => `${vm.blockClass}--${vm.size}`
        }
    }
</script>


<template>
    <div v-bind="$attrs"
            v-on="$listeners"
            role="button"
            :class="[blockClass, modifierClass]">
        <i class="icon" :class="[`${blockClass}__icon`, iconClass]"></i>
    </div>
</template>


<style lang="scss">
</style>