/**
 * Try composition api with paytype selection
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import {ref, computed} from "vue";

export const STATUS_CREATED = 'created';
export const STATUS_CANCELED = 'canceled';
export const STATUS_CONFIRMED = 'confirmed';
export const STATUS_CUSTOMER_AWAITING = 'customer_awaiting';
export const STATUS_CUSTOMER_RECEIVED = 'customer_received';
export const STATUS_INPROGRESS = 'inprogress';
export const STATUS_DELIVERING = 'delivering';

export const PAY_TYPE_COURIER_CASH_OR_CARD = 'cash';
export const PAY_TYPE_ACQUIRING = 'acquiring';
export const PAY_STATUS_AWAITING = 'awaiting';
export const PAY_STATUS_INPROGRESS = 'inprogress';
export const PAY_STATUS_PAID = 'paid';

export default function useOrderComposition() {
    const order = ref({});
    const isDeliverable = computed(() => order.value.isDeliverable);
    const isDelivered = computed(() => order.value.status === STATUS_CUSTOMER_RECEIVED);
    const isPayed = computed(() => order.value.pay_status === PAY_STATUS_PAID);

    return {
        order,
        isDeliverable,
        isDelivered,
        isPayed
    }
}