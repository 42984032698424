/**
 * The global proxy to make http requests
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import axios from "axios";
import {ElMessage} from "element-plus";

let baseUrl = process.env.VUE_APP_API_BASEURL + process.env.VUE_APP_API_PREFIX;
// provided default by env. config
let authToken = process.env.VUE_APP_AUTHTOKEN;

export function setBaseUrl(baseUrl) {
    axios.defaults.baseURL = baseUrl;
}

export function setAuthToken(token) {
    authToken = token;

    if (token && token.length) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
}

// set defaults
if (authToken) {
    setAuthToken(authToken);
}
if (baseUrl) {
    setBaseUrl(baseUrl);
}

// global requests error handling
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        let message = 'Ошибка связи';

        //handle validation errors here
        if (error.response) {
            if (error.response.status === 422) {
                message = error.response.data.map(obj => obj.message).join('<br>');
            } else if (error.response.data.message) {
                message = error.response.data.message;
            }
        }

        ElMessage.error({
            message,
            dangerouslyUseHTMLString: true,
        });

        return new Promise((res, rej) => rej(error));
    });