<script>
/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
import {ROUTE_CANCEL, ROUTE_DELIVERY, ROUTE_LIST, ROUTE_PAYMENT} from "@/router/routes_names";

export default {
    name: "OrderDetails",
    props: {
        order: {
            type: Object,
            require: true
        }
    },
    computed: {
        isPaid: vm => vm.order.isPaid === true,
        isClosed: vm => vm.order.isClosed === true,
        hasErrors: vm => vm.order.deliveryErrors && vm.order.deliveryErrors.length > 0,
        payChallengeText: vm => !vm.isPaid && `Необходимо получить ${vm.$Formatter.asCurrency(vm.order.amount)}`,
    },
    methods: {

        onSubmitClick() {
            const redirect = () => {
                this.$router.push({
                    name: ROUTE_DELIVERY, params: {
                        token: this.order.access_token
                    }
                });
            }

            // if already paid
            if (this.order.isPaid) {
                redirect();
                return;
            }

            // make confirmation
            this.$confirm('Подтвердить доставку без полной оплаты?', '', {
                confirmButtonText: 'Подтвердить',
                cancelButtonText: 'Отмена',
                type: 'info'
            }).then(() => {
                redirect();
            });
        },
        onCancelClick() {
            this.$router.push({
                name: ROUTE_CANCEL, params: {
                    token: this.order.access_token
                }
            });
        },
        onPaytypeClick(paytype) {
            this.$router.push({
                name: ROUTE_PAYMENT, params: {
                    token: this.order.access_token,
                    paytype: paytype
                }
            });
        }
    }
}
</script>


<template>
    <div class="details-view">
        <div v-if="!hasErrors">
            <section class="section client">
                <el-icon class="section__icon el-icon-s-custom"></el-icon>
                <div class="section__content">
                    <header class="section__header">
                        {{ order.client_name }}
                    </header>
                </div>
            </section>

            <section class="section payment" :class="{'section--success':order.isPaid}">
                <el-icon class="section__icon el-icon-money"></el-icon>
                <div class="section__content">
                    <header class="section__header"
                            :class="{'challenge': !order.isPaid}">{{ !order.isPaid ? payChallengeText : order.payStatusText }}
                    </header>
                    <div v-if="!order.isPaid"
                         class="paytypes">
                        <!--            <p class="challenge nomargin">{{ payChallengeText }}</p>-->
                        <div class="button-list">
                            <el-button class="button-list__button"
                                       v-for="(label, value) in order.paytypes"
                                       @click="onPaytypeClick(value)">{{ label }}
                            </el-button>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section" :class="{'section--success':order.isClosed}">
                <el-icon class="section__icon el-icon-location-information"></el-icon>
                <div class="section__content">
                    <header class="section__header"
                            v-if="order.isClosed">{{ order.statusText }}
                    </header>
                    <address>
                        {{ order.delivery_address }}
                    </address>
                    <div v-if="order.isClosed === false" class="button-list">
                        <el-button type="primary"
                                   class="button-list__button"
                                   @click.stop="onSubmitClick">
                            <slot name="default">Подтвердить доставку</slot>
                        </el-button>
                        <el-button type="danger"
                                   class="button-list__button"
                                   @click.stop="onCancelClick">
                            <slot name="default">Отказ покупателя</slot>
                        </el-button>
                    </div>
                </div>
            </section>
        </div>
        <div v-else class="deliveryErrors">
            <p v-for="error in order.deliveryErrors">{{ error }}</p>
        </div>
    </div>
</template>


<style lang="scss" scoped>
@import "../../scss/vars";

.details-view {
    padding: 0 $default-gap;
}

.el-button {
    width: 100%;
}

.button-list {
    //margin: $default-gap 0;

    &__button, &__button + &__button {
        margin: $default-gap/2 0;
    }
}

.number {
    font: bold 18px/1 $font-family-base;
    color: $color-primary;
}

.date {
    color: gray;
    font: italic 16px/1 $font-family-base;
}

.challenge {
    color: $color-danger;
}

.section {
    margin: $default-gap 0;
    display: flex;

    & + & {
        border-top: 1px solid $color-borders;
        padding-top: $default-gap;
    }

    &__icon {
        flex: 0 0 30px;
        font-size: 24px;
        opacity: .5;
    }

    &__content {
        flex: 1;
    }

    &__header {
        font: bold 18px/1.5 $font-family-base;
    }

    &--success {
        .section__icon {
            opacity: 1;
        }

        .section__icon,
        .section__header {
            color: $color-primary;
        }
    }
}


.deliveryErrors {
    margin: 10% 0;
    color: red;
    font-size: larger;
}
</style>