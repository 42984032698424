/**
 * Handle el scrolling with thresholds
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import _debounce from "lodash/debounce";

export const SCROLL_UP = 'up';
export const SCROLL_DOWN = 'down';
export const SCROLL_LEFT = 'left';
export const SCROLL_RIGHT = 'right';


/**
 * Helper to find scrollable parent
 * */
export function getScrollParent(node) {
    const isElement = node instanceof HTMLElement;
    const overflowY = isElement && window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

    if (!node) {
        console.warn("Cant detect scrollable parent");
        return null;
    } else if (isScrollable && node.scrollHeight >= node.clientHeight) {
        return node;
    }

    return getScrollParent(node.parentNode) || document.body;
}


/**
 * Кейс когда важно направление свайпа
 * Handler with direction detection
 * */
export function DirectionSwipeHandler(node, config) {
    let el = getScrollParent(node);
    let scrollTop = el.scrollTop;
    let scrollLeft = el.scrollLeft;
    let threshold = config && config.threshold && parseInt(config.threshold, 10) || 0; // default 20px
    let debounce = config && config.debounce && parseInt(config.debounce, 10) || 0; // default 20px
    let callback = config && typeof config.callback === 'function' && config.callback
        || function (e) {
            console.warn("ScrollEvents callback doesnt provide", e);
        };
    let debouncedHandleScroll = _debounce(handleScroll, debounce);

    el.addEventListener('scroll', debouncedHandleScroll, false);


    /**
     * Records location diff in px on touchmove event
     * @param {object} e - browser event object
     * @returns {void}
     */
    function handleScroll(e) {
        let direction;
        const vertDiff = scrollTop - el.scrollTop;
        const horizDiff = scrollLeft - el.scrollLeft;

        // update vals
        scrollTop = el.scrollTop;
        scrollLeft = el.scrollLeft;

        // console.log(vertDiff, horizDiff, threshold);
        if (Math.abs(vertDiff) > threshold) { // most significant
            if (vertDiff > 0) {
                direction = SCROLL_UP;
            } else {
                direction = SCROLL_DOWN;
            }
        } else if (Math.abs(horizDiff) > threshold) {
            if (horizDiff > 0) {
                direction = SCROLL_LEFT;
            } else {
                direction = SCROLL_RIGHT;
            }
        }

        if (!!direction) {
            const eventData = {
                target: el,
                direction,
                vertDiff,
                horizDiff,
                scrollTop,
                scrollLeft
            };
            callback(eventData);
        }
    }

    /**
     * Manually remove events
     * */
    this.destroyEvents = () => {
        el.removeEventListener('scroll', debouncedHandleScroll, false);
    }
}


/**
 * Кейс когда просто хотим отслеживать порог достижения скроллинга к концу элемента
 * Здесь неважно напрваление, поэтому экономим на вычислениях
 *
 * Handler to call callback when scrolling nearest node`s width/height
 * @param node HTMLElement
 * @param config Object  {
 *     threshold,
 *     callback,
 *     direction: right|bottom
 * }
 * */
export function LazyLoadHandler(node, config) {
    const callback = config && typeof config.callback === 'function' && config.callback || null;
    if (!callback) {
        throw new Error("The callback must be set");
    }

    const el = getScrollParent(node);
    const direction = config.direction === SCROLL_RIGHT ? SCROLL_RIGHT : SCROLL_DOWN;
    const threshold = config && config.threshold && parseInt(config.threshold, 10) || 20;
    const debounce = config && config.debounce && parseInt(config.debounce, 10) || 0; // default 20px
    const handleScroll = function (e) {
        const scrollOffset = direction === SCROLL_RIGHT ? el.scrollLeft : el.scrollTop;
        const scrollWidth = direction === SCROLL_RIGHT ? el.scrollWidth : el.scrollHeight;
        const clientWidth = direction === SCROLL_RIGHT ? el.clientWidth : el.clientHeight;
        if ((scrollWidth - scrollOffset - clientWidth) < threshold) {
            callback();
        }
    }

    const debouncedHandleScroll = _debounce(handleScroll, debounce);

    el.addEventListener('scroll', debouncedHandleScroll, false);


    /**
     * Manually remove events
     * */
    this.destroyEvents = () => {
        el.removeEventListener('scroll', debouncedHandleScroll, false);
    }
}