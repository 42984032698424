<script>
import SwipeEvents, {SWIPE_LEFT, SWIPE_RIGHT} from "@/utils/SwipeEvents";
import ActionsList from "@/components/ActionsList";
import {sumBy} from 'lodash'

/**
 * List item with swipe actions
 *
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
export default {
  name: "SwipeActionsListItem",
  components: {ActionsList},
  props: {
    /**
     * Defs which will be passed to ActionsList component
     * */
    actionsLeft: {
      default() {
        return [];
      }
    },
    /**
     * Defs which will be passed to ActionsList component
     * */
    actionsRight: {
      default() {
        return [];
      }
    },

    /**
     * The same defs for primary action to be called on swipe over width
     * */
    primaryActionRight: {
      type: Object
    }
  },
  data() {
    return {
      direction: null,
      actionsRightInner: this.actionsRight
    }
  },
  watch: {
    // When direction changed close opposite
    direction(v1, v2) {
      if (v2 === SWIPE_LEFT) {
        this.moveRightActions(0, 0);
      } else {
        this.moveLeftActions(0, 0);
      }
    }
  },
  computed: {
    leftActionsWidth: vm => sumBy(vm.actionsLeft, a => a.width || 60) || 0,
    hasLeftActions: vm => vm.actionsLeft.length > 0,
    rightActionsWidth: vm => sumBy(vm.actionsRightInner, a => a.width || 60) || 0,
    hasRightActions: vm => vm.actionsRightInner.length > 0,
  },
  methods: {
    moveContent(val, transition) {
      const el = this.$refs.content;
      const transform = `translateX(${val}px)`;
      el.style.webkitTransform = transform;
      el.style.mozTransform = transform;
      el.style.transform = transform;
      el.style.transition = `all ${transition}s ease-out`;
    },
    moveLeftActions(val, transition) {
      if (!this.$refs.left) {
        return;
      }
      this.$refs.left.style.width = `${val}px`;
      this.$refs.left.style.transition = `all ${transition}s ease-out`;
      this.moveContent(val, transition);
    },
    moveRightActions(val, transition) {
      if (!this.$refs.right) {
        return;
      }
      this.$refs.right.style.width = `${val}px`;
      this.$refs.right.style.transition = `all ${transition}s ease-out`;
      this.moveContent(-val, transition);
    },
    handleDrop() {
      if (this.direction === SWIPE_LEFT) {
        this.moveRightActions(0, .2);
      } else {
        this.moveLeftActions(0, .2);
      }
    },
    handleActionsLeft(e) {
      if (!this.hasLeftActions) {
        return;
      }
      let swipeOffset = e.xEnd - e.xStart;
      if (e.type === 'touchend') {
        // restrict offset with max action width and when touchend(drop) move to back
        swipeOffset = swipeOffset > this.leftActionsWidth ? this.leftActionsWidth : 0;
      }
      this.moveLeftActions(swipeOffset, 0);
    },
    handleActionsRight(e) {
      if (!this.hasRightActions) {
        return;
      }
      let swipeOffset = e.xStart - e.xEnd;
      // check primary width
      if (this.primaryActionRight) {
        // swipe over primary width
        if (swipeOffset >= this.primaryActionRight.width) {
          // If we drop on primary action
          if (e.type === 'touchend') {
            // Auto call handler
            this.primaryActionRight.handler();
            return;
          }
          // change current actions to primary
          this.actionsRightInner = [this.primaryActionRight];
        }
        // swipe lower
        else {
          // return back origin
          this.actionsRightInner = this.actionsRight;
        }
      }
      if (e.type === 'touchend') {
        // restrict offset with max action width and when touchend(drop) move to back
        swipeOffset = swipeOffset > this.rightActionsWidth ? this.rightActionsWidth : 0;
      }
      this.moveRightActions(swipeOffset, 0);
    },
    handleSwipe(e) {
      this.direction = e.direction;
      // catch focus on swiping el
      this.$refs.listitem.focus();

      if (e.direction === SWIPE_RIGHT && this.hasLeftActions) {
        this.handleActionsLeft(e);
      }
      if (e.direction === SWIPE_LEFT) {
        this.handleActionsRight(e);
      }
    },
    // Handle auto close when lost focus
    onBlur() {
      // console.log("Lost focus");
      this.handleDrop();
    }
  },
  mounted() {
    if (this.hasRightActions || this.hasLeftActions) {
      this.swipeControl = new SwipeEvents(this.$refs.listitem, {
        threshold: 30,
        callback: this.handleSwipe
      });
    }
  },
  unmounted() {
    if (this.swipeControl) {
      console.log('Destroy events');
      this.swipeControl.destroyEvents();
    }
  }
}
</script>


<template>
  <div class="list-item" ref="listitem" tabindex="0" @blur="onBlur">
    <div v-if="actionsLeft.length" class="list-item__actions-left" ref="left">
      <actions-list :actions="actionsLeft"></actions-list>
    </div>
    <div class="list-item__content" ref="content">
      <slot name="default">Swipe to actions</slot>
    </div>
    <div v-if="actionsRight.length" class="list-item__actions-right" ref="right">
      <actions-list :actions="actionsRightInner"></actions-list>
    </div>
  </div>
</template>


<style scoped lang="scss">
.list-item {
  display: flex;
  max-width: 100%;
  overflow: hidden;
  touch-action: auto;
  position: relative;
  align-items: stretch;

  &:focus {
    // disable outline on focused list item
    outline: none;
  }

  &__content {
    // make content always full width
    flex: 1 0 100%;
    // restrict with screen width/overflow
    max-width: 100%;
  }

  &__actions-left, &__actions-right {
    position: absolute;
    width: 0;
    height: 100%;
    overflow: hidden;
  }

  &__actions-left {
    left: 0;
  }

  &__actions-right {
    right: 0;
  }
}

</style>