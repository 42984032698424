<script>
/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
import debounce from "lodash/debounce";
import {inject, ref} from "vue";


export default {
    name: "OrderListSearch",
    setup() {
        const injectedQueryText = inject('queryText');
        const innerQueryText = ref(injectedQueryText.value);
        return {
            injectedQueryText,
            innerQueryText
        }
    },
    methods: {
        updateQueryText(query = '') {
            this.injectedQueryText = query.trim();
        }
    },
    created() {
        this.debouncedUpdateQueryText = debounce(this.updateQueryText, 500);
    }
}
</script>


<template>
    <el-input placeholder="Номер, адрес, ФИО, email или телефон..."
              clearable
              prefix-icon="el-icon-search"
              v-model="innerQueryText"
              @input="debouncedUpdateQueryText"
              @clear="debouncedUpdateQueryText">

    </el-input>
</template>

<style scoped>

</style>