/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import {ref} from "vue";
import axios from "axios";


export default function useOrderlistApiComposition({pageSize} = {}) {
    const orders = ref([]);
    const currentPage = ref(1);
    const isFetching = ref(false);
    const hasNextPage = ref(true);
    pageSize = pageSize || 10;
    const queryText = ref('');

    // Fetch the data
    const fetchOrders = async () => {
        if (isFetching.value) {
            console.warn('Fetching orders already busy');
            return;
        }

        let params = {
            page: currentPage.value,
            limit: pageSize,
            ...(queryText.value !== '' && {query: queryText.value})
        }

        try {
            isFetching.value = true;
            const fetchedData = (await axios.get('owner/order', {params})).data;
            orders.value = [...orders.value, ...fetchedData];
            if (fetchedData.length < pageSize) {
                hasNextPage.value = false;
            } else {
                currentPage.value++;
            }
        } catch (e) {
            console.error(e);
        } finally {
            isFetching.value = false;
        }
    }

    // Drop all states
    const resetFetchedOrders = () => {
        orders.value = [];
        currentPage.value = 1;
        hasNextPage.value = true;
    }

    // helper to reload
    const reloadOrders = () => {
        resetFetchedOrders();
        return fetchOrders();
    }

    return {
        orders,
        currentPage,
        hasNextPage,
        pageSize,
        isFetching,
        queryText,
        resetFetchedOrders,
        fetchOrders,
        reloadOrders
    }
}