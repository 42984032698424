<template>
    <sticky-header>
        <div class="toolbar">
            <div class="toolbar__item toolbar__item--flex">
                <order-list-search></order-list-search>
            </div>
            <div class="toolbar__item">
                <el-button class="qr-button"
                           @click.stop="onQrClick">
                    <img class="qr-button__img" src="@/assets/qr-code.png"/>
                </el-button>
            </div>
        </div>
    </sticky-header>
</template>

<script>
import {ROUTE_QR} from '@/router/routes_names';
import OrderListSearch from "@/views/orderlist/Search";
import IconTrigger from "@/components/my-pure/IconTrigger";
import StickyHeader from "@/components/StickyHeader";

/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
export default {
    name: "TopBar",
    components: {StickyHeader, IconTrigger, OrderListSearch},
    methods: {
        onQrClick() {
            // console.log(ROUTE_QR);
            this.$router.push({name: ROUTE_QR})
        }
    }

}
</script>


<style scoped lang="scss">
@import "../../scss/vars";

.toolbar {
    display: flex;


    &__item + &__item {
        margin-left: $default-gap/2;
    }

    &__item {
        flex: 0 0 auto;;

        &--flex {
            flex: 1;
        }
    }
}

.qr-button {
    padding: 0;
    border: 0;

    &__img {
        height: $input-height;
    }
}
</style>